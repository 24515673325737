import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import { ISimilarOrderModel } from '@models/mobx-state-tree/similarOrder.model';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import EmptyMainProductsNotification from '../includes/EmptyMainProductsNotification';
import Button from '@UIElements/Button';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import { Store } from '@store/store';
import { getDecription } from '@core/helpers/getDecription';


interface ISimilarOrderTableProps {
    similarOrders: ISimilarOrderModel[];
    isShow: boolean;
    readonly mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved: IBasketItemModel[];
    readonly animationOnOrErrorFieldsActive: boolean;
    readonly isPromotionBuilderActive: boolean;
}

const SimilarOrderTable: FunctionComponent<ISimilarOrderTableProps> = ({
    similarOrders,
    isShow = false,
    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved = [],
    animationOnOrErrorFieldsActive = false,
    isPromotionBuilderActive,
}) => {
    const [
        {
            currentOrder: {
                form: {
                    setAddressAttributes,
                    setGeneralAttributes,
                },
            },
        }, { t },
    ] = useInstances(
        Store,
        I18NService,
    );

    const copyData = (customerComponents: string | null) => {
        if (customerComponents) {
            const dectiptionData = getDecription(customerComponents);
            if (dectiptionData) {
                const { address, general } = JSON.parse(dectiptionData);
                setGeneralAttributes(general);
                setAddressAttributes(address);
            }
        }
    };

    return (
        <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
            <div className="currentProducts__table-head similarOrders-head">
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    №
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Дата', 'Date')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Тип заказа', 'Order type')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Статус', 'Status')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Имя клиента', 'Customer name')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Продукты', 'Products')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Статус выкупа', 'Buyout status')}
                </div>
            </div>
            {
                !mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved.length
                && !isPromotionBuilderActive
                && (
                    <div>
                        <div className="currentProducts__table-container similarOrders-body">
                            <div className="currentProducts__table-container-rows">
                                <EmptyMainProductsNotification
                                    className="currentProducts__table-row EmptyValueForMainProducts"
                                    animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !similarOrders.length && (
                    <strong className="currentProducts__table-noProducts">
                        {t('Нет похожих заказов', 'No similar orders')}
                    </strong>
                )
            }
            {
                similarOrders.length > 0 && similarOrders.map((similarOrder: ISimilarOrderModel) => (
                    <div key={String(similarOrder.id)}>
                        <div className={`currentProducts__table-container similarOrders-body ${similarOrder.statusCode === OrderStatusesEnum.APPROVE ? 'similarOrders-body__approve' : ''}`}>
                            <div className="currentProducts__table-container-rows">
                                <div className="currentProducts__table-row">
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.id}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col--date"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.formattedCreatedDate}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder?.type?.name ? t(similarOrder?.type?.name) : ''}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.status && t(similarOrder.status)}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-2"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.customerFullName}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-2"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.products}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {
                                                similarOrder?.isBuyout === true ? t('Да', 'Yes') :
                                                    similarOrder?.isBuyout === false ? t('Нет', 'No') :
                                                        t('В процессе', 'In process')
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {
                                                similarOrder.statusCode === OrderStatusesEnum.APPROVE &&
                                                <Button
                                                    key={'3'}
                                                    className={''}
                                                    disabled={false}
                                                    variant={'7'}
                                                    onClick={() => copyData(similarOrder?.customerComponents)}
                                                    size={'3'}
                                                    text={t('Копировать данные', 'Copy data')}
                                                    width={'auto'}
                                                    maxWidth={'90px'}
                                                    padding={'5px'}
                                                    fontSize={'11px'}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};


export default observer(SimilarOrderTable);
